import React, { Fragment } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { Modal } from "@redq/reuse-modal";
import { agencyTheme } from "../theme/agency";
import { ResetCSS } from "../assets/css/style";
import {
  GlobalStyle,
  AgencyWrapper,
} from "../containers/reusableItem/agency.style";
import Navbar from "../containers/reusableItem/Navbar";
import Banner from "../containers/reusableItem/reusableBanner/background";
import BannerContent from "../containers/reusableItem/reusableBanner/bannerContent";
import ListSektor from "../containers/Produk/ProductSection/ListSektor";
import ListCategory from "../containers/Produk/ProductSection/ListCategory";
import Footer from "../containers/reusableItem/Footer";
import { DrawerProvider } from "../contexts/DrawerContext";
import "@redq/reuse-modal/es/index.css";
import SEO from "../components/seo";
import { useIntl } from "gatsby-plugin-intl";

export default () => {
  const intl = useIntl();

  return (
    <ThemeProvider theme={agencyTheme}>
      <Fragment>
        <SEO title={intl.formatMessage({ id: "indexPageProduct" })} />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        {/* End of agency head section */}
        {/* Start agency wrapper section */}
        <AgencyWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <Banner>
            <BannerContent
              title={intl.formatMessage({ id: "indexPageProduct" })}
            />
          </Banner>
          <a id="Sektor"></a>
          <ListSektor />
          <a id="kategori"></a>
          <ListCategory />
          <Footer />
        </AgencyWrapper>
        {/* End of agency wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};
