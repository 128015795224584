import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import Box from "../../../reusecore/Box";
import Heading from "../../../reusecore/Heading";
import Image from "../../../reusecore/Image";
import Container from "../../../reusecore/UI/Container";
import ProcessItem from "./workingProcess.style";
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl";
import Image1 from "../../../assets/image/new/icon/feedadditive.png";
import Image2 from "../../../assets/image/new/icon/farmasetik.png";
import Image3 from "../../../assets/image/new/icon/disinfektan.png";
import Image4 from "../../../assets/image/new/icon/alatidentifikasi.png";


const WorkingProcessSection = ({
  sectionWrapper,
  secTitleWrapper,
  secText,
  secHeading,
  processRow,
  processCol,
  processImageStyle,
  processTitleStyle,
  processDescriptionStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasModernJson {
        categoryProd {
          title
          image {
            publicURL
          }
          slug
        }
      }
    }
  `);

  return (
    <Box {...sectionWrapper} as="section">
      <Container>
        <Box {...secTitleWrapper}>
          <Heading
            {...secHeading}
            content={<FormattedMessage id="produkKamisubTitle2" />}
          />
        </Box>

        <Box {...processRow}>
          <Box
            {...processCol}
            key={`process-item-0`}
            className="process_item_col"
          >
            <Link to="/category/FeedADSP">
              {/* <a href={item.slug}> */}
              <ProcessItem className="process_item">
                {/* <ProcessIndex>{item.index || index + 1}</ProcessIndex> */}

                <Image src={Image1} alt="not found" {...processImageStyle} />

                <Heading
                  as="h3"
                  content={<FormattedMessage id="sektor1Title" />}
                  {...processTitleStyle}
                />
              </ProcessItem>
            </Link>
          </Box>
          <Box
            {...processCol}
            key={`process-item-1`}
            className="process_item_col"
          >
            <Link to="/category/Farmasetik">
              {/* <a href={item.slug}> */}
              <ProcessItem className="process_item">
                {/* <ProcessIndex>{item.index || index + 1}</ProcessIndex> */}

                <Image src={Image2} alt="not found" {...processImageStyle} />

                <Heading
                  as="h3"
                  content={<FormattedMessage id="sektor2Title" />}
                  {...processTitleStyle}
                />
              </ProcessItem>
            </Link>
          </Box>
          <Box
            {...processCol}
            key={`process-item-2`}
            className="process_item_col"
          >
            <Link to="/category/Desinfektan">
              {/* <a href={item.slug}> */}
              <ProcessItem className="process_item">
                {/* <ProcessIndex>{item.index || index + 1}</ProcessIndex> */}

                <Image src={Image3} alt="not found" {...processImageStyle} />

                <Heading
                  as="h3"
                  content={<FormattedMessage id="sektor3Title" />}
                  {...processTitleStyle}
                />
              </ProcessItem>
            </Link>
          </Box>
          <Box
            {...processCol}
            key={`process-item-3`}
            className="process_item_col"
          >
            <Link to="/category/AlatIdentKesehatanTernak">
              {/* <a href={item.slug}> */}
              <ProcessItem className="process_item">
                {/* <ProcessIndex>{item.index || index + 1}</ProcessIndex> */}

                <Image src={Image4} alt="not found" {...processImageStyle} />

                <Heading
                  as="h3"
                  content={<FormattedMessage id="sektor4Title" />}
                  {...processTitleStyle}
                />
              </ProcessItem>
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

WorkingProcessSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  processRow: PropTypes.object,
  processCol: PropTypes.object,
  processImageStyle: PropTypes.object,
  processTitleStyle: PropTypes.object,
  processDescriptionStyle: PropTypes.object,
};

WorkingProcessSection.defaultProps = {
  sectionWrapper: {
    pt: ["60px", "15px", "15px", "15px", "15px"],
    pb: 0,
  },
  secTitleWrapper: {
    mb: ["5px", "50px"],
  },
  secText: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "14px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#2aa275",
    mb: "5px",
  },
  secHeading: {
    textAlign: "center",
    fontSize: "34px",
    fontWeight: "500",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "0",
    lineHeight: "1.67",
    mt: "2rem",
  },
  processRow: {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    flexBox: true,
    flexWrap: "wrap",
    ml: ["0", "-15px", "-20px", "-35px", "-45px"],
    mr: ["0", "-15px", "-20px", "-35px", "-45px"],
  },
  processCol: {
    width: [1, 1 / 4],
    // pl: ["0", "15px", "20px", "35px", "45px"],
    // pr: ["0", "15px", "20px", "35px", "45px"],
    // mb: "40px",
  },
  processImageStyle: {
    mb: "35px",
    width: ["100px", "80px", "70px", "100PX"],
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "1.5rem",
  },
  processTitleStyle: {
    fontSize: ["20px", "18px", "20px", "20px", "20px"],
    fontWeight: "400",
    color: "#0f2137",
    letterSpacing: "-0.02em",
    mb: ["20px", "20px", "22px", "22px", "22px"],
    textAlign: "center",
    paddingLeft: "20px",
    paddingRight: "20px",
    minHeight: "120px",
  },
  processDescriptionStyle: {
    fontSize: ["15px", "15px", "15px", "15px"],
    fontWeight: "400",
    color: "#343d48",
    lineHeight: "1.87",
  },
};

export default injectIntl(WorkingProcessSection);
